import {connect} from 'react-redux';
import {mapStateToProps} from "../../../store/helpers/mapState";
import {mapDispatchToProps} from "../../../store/helpers/mapDispatch";
import AddForm from "../../../helpers/AddForm";
import {useEffect, useState} from "react";
import PageLoading from "../../../components/PageLoading";
import {Button} from "react-bootstrap";
import moment from "moment";
import {exportCSV, generateCSV} from "../../../store/helpers/common_functions";

const RawReformReport2 = ({FetchDetails, loggedUser}) => {
    const m = moment();
    const [endDate, setEndDate] = useState(m.format("YYYY-MM-DD"));
    const [startDate, setStartDate] = useState(m.subtract(30, 'days').format("YYYY-MM-DD"));
    const [info, setInfo] = useState();
    const [loading, setLoading] = useState(false);
    const [downloading, setDownloading] = useState(false);

    const loadInfo = async () => {
        setLoading(true);
        const resp = await FetchDetails(`reports/report-2?startDate=${startDate}&endDate=${endDate}`);
        setLoading(false);
        if (resp.status) {
            setInfo(resp.data.data);
        }
    }

    useEffect(() => {
        if (startDate && endDate) {
            loadInfo().then();
        }
    }, [startDate, endDate]);
    const flattenData = (data) => {
        const rows = [];
        data.forEach((item) => {
            item.subcategories.forEach((subCategory,i) => {
                subCategory.documents.forEach((doc, index) => {
                    rows.push([
                        i === 0 && index === 0 ? item.name : "", // Category
                        index === 0 ? subCategory.name : "", // Subcategory
                        doc.name, // Document name
                        doc.status === 1 ? 'x' : '', // Status
                        doc.status === 3 ? 'x' : '', // Status
                        doc.status_publish === 1 ? 'x' : '', // Status
                        doc.section_type === 'repel' ? 'x' : '', // Status
                        doc.section_type === 'amend' ? 'x' : '', // Status
                    ]);
                });
            });
        });
        return rows;
    };
    // Quick and simple export target #table_id into a csv
    const headers = [
        "Categories of Laws",
        "Types of laws",
        'Titles/Names of created laws',
        "Laws under review",
        "Pending due to requests for action",
        "Published",
        "Amended",
        "Repealed"
    ];
    useEffect(() => {
        if (downloading) {
            const rows = flattenData(info);

// Generate and export CSV
            const csvContent = generateCSV(headers, rows);
            exportCSV(csvContent, `rlrc_report_2_${m.format("YYYY_MM_DD_mm_ss")}.csv`);
            setDownloading(false);
        }
    }, [downloading]);

    return <div style={{color: "#3a3a3a"}}>
        <AddForm hideSubmitButton={true} inline={true} col={6} title={"Generate Law Reform Report"} mainFields={[
            {
                title: "",
                fields: [
                    {
                        name: "startDate",
                        label: "Start Date",
                        type: "date",
                        value: startDate,
                        setValue: setStartDate
                    },
                    {
                        name: "endDate",
                        label: "End Date",
                        type: "date",
                        value: endDate,
                        setValue: setEndDate
                    }
                ]
            }
        ]}/>

        {loading || !info ? <PageLoading error={!loading ? 'Loading info Error' : null}/> : <div className={'card'}>
            <div className={'card-body'}>
                <div className={'clearfix mb-2'}>
                    <div className={'float-end'}>
                        <Button size={'sm'} onClick={() => setDownloading(true)}>Generate CSV Report</Button>
                    </div>
                </div>
                <div className={'table-responsive'}>
                    <table className={'table table-striped'} id={"datatable"}>
                        <thead>
                        <tr>
                            {headers.map(v => <th>{v}</th>)}
                        </tr>
                        </thead>
                        <tbody>
                        {
                            info.map((item, index) => {
                                return item.subcategories.map((subItem, index0) => {
                                    return subItem.documents.map((doc, index2) => {
                                        return <>
                                            <tr>
                                                {index0 === 0 && index2 === 0 && <td style={{verticalAlign:'top'}}
                                                                                     rowSpan={item.subcategories.reduce((a, b) => a + b.documents.length, 0)}>{item.name}</td>}
                                                {index2 === 0 && <td
                                                                     style={{verticalAlign:'top',backgroundColor: index0 % 2 === 0 ? '#8a92a608' : '#ffffff'}}
                                                                     rowSpan={subItem.documents.length}>{subItem.name}</td>}
                                                <td style={{whiteSpace:'wrap'}}>{doc.name}</td>
                                                <td>{doc.status === 1 && <i className={'fa fa-check'}></i> }</td>
                                                <td>{doc.status === 3 && <i className={'fa fa-check'}></i>}</td>
                                                <td>{doc.status_publish === 1 && <i className={'fa fa-check'}></i> }</td>
                                                <td>{doc.section_type === 'repel' && <i className={'fa fa-check'}></i>}</td>
                                                <td>{doc.section_type === 'amend' && <i className={'fa fa-check'}></i>}</td>
                                            </tr>
                                        </>;
                                    });
                                });
                            })
                        }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>}
    </div>
}


export default connect(mapStateToProps, mapDispatchToProps)(RawReformReport2);
