import React, {useEffect} from "react";
import {mapDispatchToProps} from "../../../../store/helpers/mapDispatch";
import {connect} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {Col, FormGroup, FormLabel, Row} from "react-bootstrap";
import {Button, Card, CardContent, CardHeader, Chip} from "@mui/material";
import DatatableComponent from "../../../../helpers/DatatableComponent";
import Status from "../../../../components/partials/status";
import PublishStatus from "../../../../components/partials/publish_status";
import Comments from "../../../../components/partials/comments";
import SubmitApplication from "../../../../components/partials/submit_application";
import {mapStateToProps} from "../../../../store/helpers/mapState";
import {formatLang, languages_const} from "../ocr_scan";
import {buildOfficeUrl, wordMime} from "../../../../store/helpers/common_functions";

const DecisionDetails = (props) => {
    const {FetchDetails} = props;
    const [info, setInfo] = React.useState();
    const navigate = useNavigate();
    const [loadingPage, setLoadingPage] = React.useState(false);

    const {id} = useParams();


    const loadInfo = async () => {
        setLoadingPage(true);
        const resp = await FetchDetails(`documents/${id}`);
        setLoadingPage(false);
        if (resp.status) {
            const data = resp.data?.data;
            setInfo(data);
        }
    }


    useEffect(() => {
        if (id && !info) {
            loadInfo().then();
        }
    }, [id, info]);

    return loadingPage ?
        <div style={{minHeight: "70vh"}} className={'d-flex align-items-center justify-content-center'}>
            <h3>Loading ......</h3>
        </div> : <div>
            <Card>
                <CardHeader title={'Decision Details'}/>
                <CardContent>
                    <Row>
                        <Col md={4}>
                            <FormGroup className={'mb-3'}>
                                <span className={'fw-bold'}>Case Name</span>
                                <div>{info?.name}</div>
                            </FormGroup>
                        </Col>
                        <Col md={4}>
                            <FormGroup className={'mb-3'}>
                                <span className={'fw-bold'}>Date</span>
                                <div>{info?.date}</div>
                            </FormGroup>
                        </Col>
                        <Col md={4}>
                            <FormGroup className={'mb-3'}>
                                <span className={'fw-bold'}>Collection</span>
                                <div>{info?.collection?.name_en}</div>
                            </FormGroup>
                        </Col>
                        <Col md={4}>
                            <FormGroup className={'mb-3'}>
                                <span className={'fw-bold'}>Case name</span>
                                <div>{info?.name}</div>
                            </FormGroup>
                        </Col>
                        <Col md={4}>
                            <FormGroup className={'mb-3'}>
                                <span className={'fw-bold'}>Case numbrt</span>
                                <div>{info?.case_no}</div>
                            </FormGroup>
                        </Col>
                        <Col md={4}>
                            <FormGroup className={'mb-3'}>
                                <span className={'fw-bold'}>Neutral citation</span>
                                <div>{info?.neutral_citation}</div>
                            </FormGroup>
                        </Col>
                        <Col md={4}>
                            <FormGroup className={'mb-3'}>
                                <span className={'fw-bold'}>Reported Citation</span>
                                <div>{info?.report_citation}</div>
                            </FormGroup>
                        </Col>
                        {/*<Col md={4}>*/}
                        {/*    <FormGroup className={'mb-3'}>*/}
                        {/*        <span className={'fw-bold'}>Parties</span>*/}
                        {/*        <div>{info?.parties}</div>*/}
                        {/*    </FormGroup>*/}
                        {/*</Col>*/}
                        <Col md={4}>
                            <FormGroup className={'mb-3'}>
                                <span className={'fw-bold'}>Relation</span>
                                <div>{info?.relations?.name_en}</div>
                            </FormGroup>
                        </Col>
                        <Col md={4}>
                            <FormGroup className={'mb-3'}>
                                <span className={'fw-bold'}>Decision Type</span>
                                <div>{info?.decisionsTypes?.name_en}</div>
                            </FormGroup>
                        </Col>
                        <Col md={4}>
                            <FormGroup className={'mb-3'}>
                                <span className={'fw-bold'}>Decision Status</span>
                                <div>{info?.decisionsStatus?.name_en}</div>
                            </FormGroup>
                        </Col>
                        <Col md={4}>
                            <FormGroup className={'mb-3'}>
                                <span className={'fw-bold'}>Status</span>
                                <div><Status status={info?.status} info={info}/></div>
                            </FormGroup>
                        </Col>
                    </Row>
                    <div>
                        <Comments comments={info?.comments}/>
                    </div>
                    <div>
                        <DatatableComponent componentClass={'p-0'} elevation={1} titleClass={'p-0'}
                                            deleteUrl={(id) => ({
                                                url: `documents-files/action/single`,
                                                post: true,
                                                body: {
                                                    id,
                                                    action: "delete"
                                                }
                                            })} columns={[
                            {
                                name: "languages",
                                label: "Language",
                                options: {
                                    customBodyRender:formatLang
                                }
                            },
                            {
                                name: "id", label: "Document", options: {
                                    customBodyRender: (id,table,_data) => <Button size={'small'} onClick={() => {
                                        if(_data.file?.mime === wordMime){
                                            window.open(buildOfficeUrl(props.loggedUser?.office_key,id));
                                        }else{
                                            navigate(`/dashboard/view/document/${id}`);
                                        }
                                    }} variant={'outlined'}>View</Button>
                                }
                            },
                            {
                                name: "file_hyperlink_status", label: "Hyperlink Status", options: {
                                    customBodyRender: (status) => <Chip color={status === 1 ? 'warning' : 'success'} label={status === 1 ? 'Scanning ...' : 'Completed'} size={'small'} variant={'outlined'}/>
                                }
                            },
                        ]} url={`documents-files/table?document=${id}`}/>
                    </div>
                    <SubmitApplication {...props} info={info} onSuccess={loadInfo}/>
                </CardContent>
            </Card>
        </div>
}

export default connect(mapStateToProps, mapDispatchToProps)(DecisionDetails);
