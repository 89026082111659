import {connect} from 'react-redux';
import {mapStateToProps} from "../../../store/helpers/mapState";
import {mapDispatchToProps} from "../../../store/helpers/mapDispatch";
import AddForm from "../../../helpers/AddForm";
import {useEffect, useState} from "react";
import PageLoading from "../../../components/PageLoading";
import {Button} from "react-bootstrap";
import moment from "moment";
import {exportCSV, generateCSV} from "../../../store/helpers/common_functions";
const RawReformReport = ({FetchDetails,loggedUser})=>{
    const m = moment();
    const [endDate, setEndDate] = useState(m.format("YYYY-MM-DD"));
    const [startDate, setStartDate] = useState(m.subtract(30,'days').format("YYYY-MM-DD"));
    const [info, setInfo] = useState();
    const [loading, setLoading] = useState(false);
    const [downloading, setDownloading] = useState(false);

    const loadInfo = async ()=>{
        setLoading(true);
        const resp = await FetchDetails(`reports/report-1?startDate=${startDate}&endDate=${endDate}`);
        setLoading(false);
        if(resp.status){
            setInfo(resp.data.data);
        }
    }

    useEffect(() => {
        if(startDate && endDate){
            loadInfo().then();
        }
    }, [startDate,endDate]);

    // Quick and simple export target #table_id into a csv
    const flattenData = (data) => {
        const rows = [];
        data.forEach((item) => {
            item.subcategories.forEach((subCategory,index) => {
                    rows.push([
                        index === 0 ? item.name : "", // Category
                        subCategory.name, // Subcategory
                        subCategory.counts.review, // Document name
                        subCategory.counts.rejected, // Status
                        subCategory.counts.published, // Status
                        subCategory.counts.amended, // Status
                        subCategory.counts.repelled, // Status
                    ]);
            });
        });
        return rows;
    };

    const headers = [
        "Categories of Laws",
        "Types of laws",
        "Laws under review",
        "Pending due to requests for action",
        "Published",
        "Amended",
        "Repealed"
    ];
    useEffect(() => {
        if(downloading){
            const rows = flattenData(info);

// Generate and export CSV
            const csvContent = generateCSV(headers, rows);
            exportCSV(csvContent, `rlrc_report_1_${m.format("YYYY_MM_DD_mm_ss")}.csv`);
        }
    }, [downloading]);

    return <div style={{color: "#3a3a3a"}}>
        <AddForm hideSubmitButton={true} inline={true} col={6} title={"Generate Law Reform Report"} mainFields={[
            {
                title:"",
                fields:[
                    {
                        name:"startDate",
                        label:"Start Date",
                        type:"date",
                        value:startDate,
                        setValue:setStartDate
                    },
                    {
                        name:"endDate",
                        label:"End Date",
                        type:"date",
                        value: endDate,
                        setValue:setEndDate
                    }
                ]
            }
        ]}/>

        {loading || !info ? <PageLoading error={!loading ? 'Loading info Error' : null}/> : <div className={'card'}>
            <div className={'card-body'}>
                <div className={'clearfix mb-2'}>
                    <div className={'float-end'}>
                        <Button size={'sm'} onClick={()=>setDownloading(true)}>Generate CSV Report</Button>
                    </div>
                </div>
                <div className={'table-responsive'}>
                    <table className={'table table-striped'} id={"datatable"}>
                        <thead>
                        <tr>
                            {headers.map(v=><th>{v}</th>)}
                        </tr>
                        </thead>
                        <tbody>
                        {
                            info.map((item,index)=>  {
                                return item.subcategories.map((subItem,index)=>  {
                                    return <>
                                        <tr>
                                            {index ===0 &&<td rowSpan={item.subcategories.length}>{item.name}</td>}
                                            <td>{subItem.name}</td>
                                            <td>{subItem.counts.review}</td>
                                            <td>{subItem.counts.rejected}</td>
                                            {/*<td>{subItem.submitters?.map(x=>x.name)?.join(",")}</td>*/}
                                            <td>{subItem.counts.published}</td>
                                            <td>{subItem.counts.amended}</td>
                                            <td>{subItem.counts.repelled}</td>
                                        </tr>
                                    </>;
                                });
                            })
                        }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>}
    </div>
}


export default connect(mapStateToProps,mapDispatchToProps)(RawReformReport);
