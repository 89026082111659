import moment from "moment";
import axios from "axios";
import {header} from "../User/action";

export const wordMime = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';


export const defaultFilters = [
    {
        name:"status",
        label:"Status",
        type:"select",
        values:[
            {
                value:"0",
                label:"Draft"
            },
            {
                value:"1",
                label:"Pending for review"
            },
            {
                value:"2",
                label:"Approved pending for publication"
            },
            {
                value:"3",
                label:"Action requested"
            },
        ]
    },
    {
        name:"status_publish",
        label:"Publish Status",
        type:"select",
        values:[
            {
                value:"0",
                label:"Pending"
            },
            {
                value:"1",
                label:"Published"
            },
            {
                value:"2",
                label:"Hold"
            },
            {
                value:"3",
                label:"Unpublished"
            },
        ]
    },
    {
        name:"date",
        label:"Date",
        type:"date"
    }
];


export const generateCSV = (headers, rows) => {
    return [
        headers.join(","), // Add headers
        ...rows.map((row) => row.map((value) => `"${value}"`).join(",")), // Add rows
    ].join("\n");
};
// Export CSV
export const exportCSV = (csvContent, fileName) => {
    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = fileName;
    link.click();

    URL.revokeObjectURL(url); // Clean up
};

export const buildOfficeUrl = (officeKey,fileId)=>{
    return `${process.env.REACT_APP_ONLY_OFFICE_URL}/${officeKey}/${fileId}`;
}

export const getQuery = (key) => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    return params.get(key);
}

export const errorInterceptor = async (error) => {
    // whatever you want to do with the error
    // console.log(er)
    if (error?.response?.status === 401 && localStorage.getItem("token")) {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        window.location.reload();
        throw error?.response;
    }

    if (error?.response?.status === 409) {
        const user = localStorage.getItem("user");
        if(user) {
            localStorage.setItem("user", JSON.stringify(
                {
                    ...JSON.parse(user),
                    password_reset: true
                }
            ));
            window.location.reload();
        }else{
            let email = "";
            if(error?.config?.data){
                let body = JSON.parse(error.config.data);
                email = `?email=${encodeURIComponent(body.email)}`;
            }
            window.location.href = `/auth/change-password${email}`
        }
        throw error?.response;
    }

    throw error?.response;
}

export const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
});

export function blobToBase64(blob) {
    return new Promise((resolve, _) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blob);
    });
}
/**
 * =========================== downloadBase64 =======================
 * @param {*} url
 * @param {*} body
 * @param {*} fileType
 * @returns
 */
export const downloadBase64 = async (
    url,
    body,
    fileType,
) => {
    try {

        const axios_URL = `${url}`;
        const { data } = await axios.post(axios_URL,body, {
            ...header,
            responseType: "blob",
        });
        if (data) {
            const file = new Blob([data], {
                type: fileType,
            });
            return await blobToBase64(file);
        }
    } catch (error) {
        return null;
    }
};

export const downloadBase64Get = async (
    url,
    fileType,
) => {
    try {

        const axios_URL = `${url}`;
        const { data } = await axios.get(axios_URL, {
            responseType: "blob",
        });
        if (data) {
            const file = new Blob([data], {
                type: fileType,
            });
            return await blobToBase64(file);
        }
    } catch (error) {
        return null;
    }
};
export const giftConditions = [
    {
        label: "Good Condition",
        value: "Good Condition"
    },
    {
        label: "Damaged",
        value: "Damaged"
    },
    {
        label: "Used",
        value: "Used"
    },
    {
        label: "Exited",
        value: "Exited"
    },
];

export const inArray = (haystack, needle) => {
    if (!haystack) {
        return false;
    }

    return haystack.filter(v => v === needle).length > 0;
}
export const commonError = "Unexpected Error or Server Error, Contact Admin !!";


export const getFileMime = (name) => {
    let lowerCase = name?.split(".")?.pop()?.toLowerCase();
    return isImageExt(lowerCase) ? `image/${lowerCase}` :
        inArray(['pdf'], lowerCase) ? 'application/pdf' :
            inArray(['mov', 'mp4', 'mkv', 'wmv', 'avi', 'webm'], lowerCase) ? `video/${lowerCase}` :
                'application/octet-stream';
}

export const getError = (resp) => {
    const msg = resp?.data?.data?.message ?? resp?.data?.message ?? resp?.response?.data?.message ?? commonError;
    if(msg?.message){
        return msg.message;
    }

    return typeof msg === 'string' ? msg : commonError;
}
export const getSuccessMessage = (resp) => {
    const msg = resp?.data?.message ?? "Success !";
    if(msg?.message){
        return msg.message;
    }
    return typeof msg === 'string' ? msg : "Success !";
}

export const isImageExt = (name) => {
    return inArray(['jpg', 'png', 'webp', 'jpeg', 'gif'], name);
}


export const allowedTypes = ".pdf,image/*,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";


export const getDuration = (end, date = null) => {
    if (end) {
        let years = moment(end).diff(date ?? moment(), "years");
        if (years > 0) {
            return `${years} yrs`;
        }
        let months = moment(end).diff(date ?? moment(), "months");
        if (months > 0) {
            return `${months} mo`;
        }
        let days = moment(end).diff(date ?? moment(), "days");

        if (days > 0) {
            return `${days} dys`;
        }
        let hours = moment(end).diff(date ?? moment(), "hours");

        if (hours > 0) {
            return `${hours} hrs`;
        }

        let minutes = moment(end).diff(date ?? moment(), "minutes");

        if (minutes < 0) {
            return `-${getDuration(new Date(), end)}`;
        }

        return `${minutes} min`;


    } else {
        return "Un-estimated";
    }
};

export const requestInterceptor = (request) => {
    const token = localStorage.getItem("token");
    if (request.headers && request.headers['access-token'] !== token && token) {
        request.headers['access-token'] = token;
    }
    return request;
}

export const responseInterceptor = (response) => {
    localStorage.setItem("lastSeen", new Date().toString());
    return response
}
